main {
  margin-top: 24px;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

button.MuiButtonBase-root {
  letter-spacing: 2px;
  font-weight: 700;
  text-transform: none;
}

h2.MuiTypography-h2 {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  font-size: 1.75rem;
  line-height: 1.2;
  letter-spacing: -0.00833em;
  margin-bottom: 0.35em;
  margin-top: 24px;
}

h5 {
  color: #f78da7;
  margin: 1em 0;
  font-weight: 700;
  font-size: 16px;
}

.purple-color {
  color: #9b51e0;
}

.gray-color {
  color: #abb8c3;
}

figure {
  margin: 0;
}

figcaption {
  caption-side: bottom;
  margin: 0.8075em 0 0 0;
  font-size: 0.7778em;
  color: #999;
}

img.mask {
  -webkit-mask-image: url("https://d30ay7ug033loj.cloudfront.net/images/mask-8.png");
  mask-image: url("https://d30ay7ug033loj.cloudfront.net/images/mask-8.png");
  mask-size: cover;
  -webkit-mask-size: cover;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
}

code {
  white-space: pre-wrap;
  font-size: 10px;
  padding: 10px;
	color: #333;
}

.pre-code {
  overflow: auto;
  width: 100%;
  background: #efefef;
	border: 1px solid #777;
  padding: 4px;
}

.gallery {
  line-height: 0;
}

.gallery img {
  margin: 2px;
}