main {
  margin-top: 24px;
}

a:link, a:visited, a:hover, a:active {
  text-decoration: none;
}

button.MuiButtonBase-root {
  letter-spacing: 2px;
  text-transform: none;
  font-weight: 700;
}

h2.MuiTypography-h2 {
  letter-spacing: -.00833em;
  margin: 24px 0 .35em;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 1.75rem;
  font-weight: 300;
  line-height: 1.2;
}

h5 {
  color: #f78da7;
  margin: 1em 0;
  font-size: 16px;
  font-weight: 700;
}

.purple-color {
  color: #9b51e0;
}

.gray-color {
  color: #abb8c3;
}

figure {
  margin: 0;
}

figcaption {
  caption-side: bottom;
  color: #999;
  margin: .8075em 0 0;
  font-size: .7778em;
}

img.mask {
  -webkit-mask-image: url("https://d30ay7ug033loj.cloudfront.net/images/mask-8.png");
  mask-image: url("https://d30ay7ug033loj.cloudfront.net/images/mask-8.png");
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

code {
  white-space: pre-wrap;
  color: #333;
  padding: 10px;
  font-size: 10px;
}

.pre-code {
  width: 100%;
  background: #efefef;
  border: 1px solid #777;
  padding: 4px;
  overflow: auto;
}

.gallery {
  line-height: 0;
}

.gallery img {
  margin: 2px;
}

/*# sourceMappingURL=index.f0588718.css.map */
